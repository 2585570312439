/* src/components/Proyectos/CardProject.css */
.project-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.project-card:hover {
  transform: scale(1.05);
}

.project-card img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.carruselproject {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.project-title {
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
}

.project-sub {
  text-align: center;
  font-size: 1rem;
}

.project-text {
  font-size: 1rem;
  color: #555;
  flex-grow: 1;
}

.title-project {
  text-align: center;
  margin: 20px 0;
  font-size: 2rem;
  color: #333;
}

.carousel-ud{
  height: 300px;
}
