/* src/components/Footer.css */
.footer {
  background-color: rgba(203, 203, 203, 0.727);
  padding: 20px 0;
}

.footer-content {
  text-align: left;
}

.footer-links {
  text-align: right;
}

.footer-links p {
  margin-bottom: 10px;
}

.contact-button {
  display: block;
}

.button {
  margin-bottom: 10px;
}

.subtitleFooter{
  font-weight: 700;
}

.emailFooter{
  font-size: 18px;
}

.titleFooter{
  color: rgba(0, 118, 102, 1);
}