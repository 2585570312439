.image-publish {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.titlepost{
  font-weight: 700;
  font-size: 35px;
}
.textpost{
  font-size: 25px;
  text-align: justify;
  font-weight: 800px;
}


.containerPosts{
  margin-top: 20px;
  margin-bottom: 20px;
}

.postButton{
  margin-right: 10px;
}

.imgPost{
  width: 100%;
  height: 400px; /* Ajusta esta altura según tus necesidades */
  object-fit: cover;
}
