.title-verticales {
  text-align: center;
  font-size: 45px;
}

.verticales-title {
  font-size: 30px;
  font-weight: 700;
}

.verticales-sub {
  font-size: 25px;
  font-weight: 700;
}

.verticales-text {
  font-size: 20px;
}

.button-cfg {
  bottom: 0px; /* Espacio desde la parte inferior */
  left: 0; /* Alinear a la izquierda */
  width: 100%;
  background-color: rgb(147, 185, 59);
  border-color: rgb(91, 130, 0);
}
