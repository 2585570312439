.navbar-brand-custom {
    margin-left: 10px; /* Esto genera un espacio a la izquierda del Navbar para que el titulo no se vea adherido al borde. */
    font-size: 45px;
    font-weight: 700;
    display: flex;
    align-items: center;
  }
.bg-custom {
  background-color: rgba(188, 188, 188, 0.834); /* Color de la barra de Navegacion. */
  height: 100%;
}

.navbar-brand {
  margin-right: auto;
}

.navbar-nav .nav-link {
  color: rgba(0, 118, 102, 1); /* Cambia el color del texto para mejor visibilidad */
  font-weight: 700;
  margin: 10px;
  font-size: 20px;
}

.navbar-nav .nav-link:hover {
  color: rgb(255, 255, 255); /* Cambia el color del texto al pasar el mouse en las opciones de la izquierda*/
}

.nav-logo {
  max-height: 50px; /* Ajusta la altura según sea necesario */
  margin-right: 10px; /* Espacio entre las imágenes y el texto */
}

.navbar-brand-custom img:first-child {
  margin-right: 5px; /* Espacio entre las dos imágenes */
}

.text-agro{
  color: rgba(0, 118, 102, 1);
}

.text-conecta{
  color: rgb(0, 95, 171);
}

.textNav{
  text-align: center;
}


/* Especificacion Movil */
@media (max-width: 768px) {
  .navbar-brand-custom {
    margin-left: 5px; /* Esto genera un espacio a la izquierda del Navbar para que el titulo no se vea adherido al borde. */
    font-size: 20px;
    font-weight: 700;
    display: flex;
    align-items: center;
  }
}
