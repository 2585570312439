.introhome-caption {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    padding: 20px;
    width: 80%;
    height: 80%;
}

.introhome-intro {
    height: 700px;
    position: relative;
}

.introhome-intro img {
    height: 100%;
    width: 100%;
    object-fit: cover; /* Para que las imágenes se adapten al contenedor */
}

.introhome-paragraph {
    margin: 30px 0;
    font-size: 30px;
}

.introhome-buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.introhome-button {
    padding: 0.5rem 1rem;
    border: none;
    cursor: pointer;
    color: #fff;
    font-size: 1rem;
    border-radius: 5px;
}

.introhome-boton1 {
    font-size: 20px;
}

.introhome-boton2 {
    font-size: 20px;
}

/* Mobile Design */
@media (max-width: 768px) {
    .introhome-intro {
        height: fit-content;
    }

    .introhome-caption {
        position: relative;
        top: 0;
        left: 0;
        transform: none;
        width: 100%;
        height: auto;
    }

    .introhome-paragraph {
        font-size: 13px;
    }

    .introhome-buttons {
        flex-direction: column;
        gap: 0.5rem;
    }

    .introhome-button {
        width: 100%;
        font-size: 16px;
    }
}
