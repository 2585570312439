.image-publish {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .carousel-publish {
    height: 100%;
  }
  
  .titlepost{
    font-weight: 700;
  }
  .textpost{
    font-size: 20;
    text-align: justify;
  }