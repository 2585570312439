.introtitle{
    font-size: 80px;
}
.intro-text{
    margin: 20px;
    font-size: 30px;
    align-items: center;
}
/* Intro.css */
.card-intro{
    font-size: 30px;
    display: flex;
    align-items: center; /* Centra verticalmente el contenido */
    text-align: justify;
}

.agro-intro{
    color: rgba(0, 118, 102, 1);
  }
  
  .conecta-intro{
    color: rgb(0, 95, 171);
  }

  @media (max-width: 768px) {
    .introtitle{
        font-size: 45px;
    }
    .intro-text{
        margin: 20px;
        font-size: 20px;
    }
    
  }