/* src/components/Home/About.css */
.about-container {
    position: relative;
    width: 100%;
    height: 400px; /* Altura de la imagen */
    background-size: cover;
    background-position: center;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Sombreado interior */
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
  }
  
  .overlay h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .overlay p {
    font-size: 1.2rem;
  }
  
  .color-button{
    background-color: rgb(147, 185, 59);
  }


  @media (max-width: 768px) {
    .about-container {
      position: relative;
      width: 100%;
      height: 600px; /* Altura de la imagen */
      background-size: cover;
      background-position: center;
    }
}