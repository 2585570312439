/* src/components/Training/Training.css */

.training-container {
    flex-direction: column;
    justify-content: space-around; /* Espacio igual entre cada fila */
  }
  
  .training-main-title {
    text-align: center;
    margin-top: 20px;
    font-weight: bold;
  }
  
  .training-main-text {
    text-align: center;
    margin-bottom: -10px;
    font-size: 1.2rem;
  }
  
  .training-row {
    align-items: center;
    margin: 40px 0; /* Margen para separarlos un poco */
  }
  
  .training-card {
    display: flex;
    flex-direction: row;
  }
  
  .no-gutters {
    margin-right: 0;
    margin-left: 0;
  }
  
  .training-image-col {
    padding: 0;
  }
  
  .training-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .training-content-col {
    display: flex;
    align-items: center;
    padding: 0;
  }
  
  .training-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
    font-weight: 700;
  }
  
  .training-text {
    font-size: 1rem;
    margin-bottom: 20px;
  }
  
  .training-button {
    font-size: 1rem;
  }
  
  .cap-button {
    bottom: 0px; /* Espacio desde la parte inferior */
    left: 0; /* Alinear a la izquierda */
    margin: 20px;
    background-color: rgb(147, 185, 59);
    border-color: rgb(91, 130, 0);
  }