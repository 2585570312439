.testimonyBG {
  background-color: #f9f9f9; /* Ajusta el color de fondo según sea necesario */
}

.titleTesmony {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.carouselTestimony {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Ajusta la altura del carrusel */
}

.videoTestimony {
  border-radius: 10px;
  max-height: 500px; /* Ajusta esta altura según sea necesario */
  width: 100%;
}

/* Mobile Design */
@media (max-width: 768px) {
  .titleTesmony {
      font-size: 1.5rem;
  }
  
  .videoTestimony {
      max-height: 300px; /* Ajusta esta altura según sea necesario para dispositivos móviles */
      width: 100%;
  }
}
