/* src/components/Home/Cards.css */
/* src/components/Home/Cards.css */
.custom-card {
  margin: 10px;
  margin-bottom: -10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.custom-card-title {
  font-family: 'GobCL', sans-serif;
  font-weight: 700;
  text-align: center; /* Alinea el texto del título al centro */
}

.custom-card-text {
  margin: 5px;
  text-align: justify;
  font-size: 16px;
}

.custom-button {
  bottom: 0px; /* Espacio desde la parte inferior */
  left: 0; /* Alinear a la izquierda */
  width: 85%;
  margin: 20px;
  background-color: rgb(147, 185, 59);
  border-color: rgb(91, 130, 0);
}