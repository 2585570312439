
@font-face {
  font-family: 'GobCL';
  src: url('./assets/font/gobCL_Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'GobCL';
  src: url('./assets/font/gobCL_Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

body {
  font-family: 'GobCL', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'GobCL', sans-serif;
  font-weight: 700;
}

p {
  font-family: 'GobCL', sans-serif;
  font-weight: 300;
}

